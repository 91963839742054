import axios from "axios";

export default {
  async getData(id) {
    return await axios
      .get("auction-allocation/master-by-season-sale/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async singleExcelImport(id, name) {
    return await axios
      .get(`auction-allocation/list-download/${id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `S-${name}-Tasting-Allocation.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
};
