<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Allocation
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-form ref="frm">
                  <v-card-text class="px-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Auction center</label
                        >

                        <v-autocomplete
                          :items="auctionCenter"
                          item-text="auction_center_name"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.auctionCenter"
                          @change="resetSeason"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          outlined
                          height="46"
                          single-line
                          :rules="[(v) => !!v || 'Auction center is required']"
                          placeholder="Select auction center"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Season</label
                        >

                        <v-autocomplete
                          :items="season"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.season"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          return-object
                          outlined
                          height="46"
                          @change="filterSeasonSale()"
                          single-line
                          :rules="[(v) => !!v || 'Season is required']"
                          placeholder="Select Season"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Sale No</label
                        >

                        <v-autocomplete
                          :items="seasonSale"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.seasonSale"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          return-object
                          outlined
                          height="46"
                          single-line
                          :rules="[(v) => !!v || 'Sale No is required']"
                          placeholder="Select Sale No"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-light
                        px-6
                      "
                      >Clear</v-btn
                    >

                    <v-btn
                      @click="search()"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-primary
                        bg-success
                        py-5
                        px-5
                        shadow
                      "
                    >
                      Search</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-show="showTable">
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                item-key="id"
                class="table"
                mobile-breakpoint="0"
                fixed-header
                disable-pagination
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <!-- <span> Example test {{ item.district }}</span> -->
                  <v-btn
                    @click="download(item)"
                    icon
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class=""
                    color="green"
                    >Download
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { format } from "date-fns";
import api from "../apis";
import apiMaster from "../../../master/master-get-api";
export default {
  name: "Allocation",
  data() {
    return {
      page: 1,
      pageCount: 0,
      overlay: false,
      auctionCenter: [],
      seasonSale: [],
      showTable: false,

      season: [],

      itemsPerPage: 10,

      editedItem: {
        auctionCenter: null,
        seasonSale: null,

        list_name: "",
        season: null, // not used in API- just to filter Sale No
      },
      defaultItem: {
        auctionCenter: null,
        seasonSale: null,

        list_name: "",
        season: null, // not used in API- just to filter Sale No
      },
      headers: [
        {
          text: "Name",
          width: 250,
          value: "list_name",
        },
        {
          text: "Auction center",
          width: 100,
          value: "auction_center_name",
        },
        {
          text: "Season",
          width: 20,
          value: "name",
        },
        {
          text: "Season Sale",
          width: 20,
          value: "season_sale",
        },
        {
          text: "",
          width: "80",
          value: "actions",
          sortable: false,
        },
      ],
      items: [],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    async resetSeason() {
      this.editedItem.season = null;
      this.editedItem.seasonSale = null;
      this.seasonSale = [];
    },
    async download(item) {
      this.overlay = true;
      await api.singleExcelImport(item.id, item.list_name);
      this.overlay = false;
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,

        showConfirmButton: false,
      });
    },

    async initialize() {
      try {
        this.auctionCenter = await apiMaster.getAuctionCenter();

        this.season = await apiMaster.getSeason();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    async filterSeasonSale() {
      if (this.editedItem.auctionCenter) {
        this.overlay = true;
        this.editedItem.seasonSale = {};
        this.seasonSale = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
          this.editedItem.season.id,
          this.editedItem.auctionCenter
        );
        this.overlay = false;
      }
    },
    async search() {
      if (this.$refs.frm.validate()) {
        this.showTable = false;
        this.overlay = true;
        this.items = await api.getData(this.editedItem.seasonSale.id);
        this.overlay = false;
        if (this.items.length > 0) {
          this.showTable = true;
        } else {
          this.noDataAlert("No Data Found...");
        }
      }
    },
    close() {
      // this.$refs.frm.reset();
      this.showTable = false;
      this.items = [];
      this.editedItem = {
        auctionCenter: 0,
        seasonSale: 0,
        list_name: "",
        season: 0, // not used in API- just to filter Sale No
      };
      this.$refs.frm.resetValidation();
    },
  },
};
</script>
